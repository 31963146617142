// @ts-ignore
import './components/i18n-text/i18n-text.js';

import './components/update-app/update-app.js';
import './components/app-header/app-header.js';
import './components/my-podcasts/my-podcasts.js';
import './components/in-progress-episodes/in-progress-episodes.js';
import './components/side-menu/side-menu.js';
import './components/loading-spinner/loading-spinner.js';
import './components/audio-player/audio-player.js';
import './components/podcast-show-row/podcast-show-row.js';
import './components/local-date/local-date.js';
import './components/hh-mm-ss/hh-mm-ss.js';
import './components/religion-warning/religion-warning.js';
import './components/fallback-image.js';

if (!('mediaSession' in navigator)) {
  // @ts-ignore
  import('./polyfills/mediaSession.js');
}

document.addEventListener('nattramn-router:loading', event => {
  if (event instanceof CustomEvent) {
    const loading = event.detail;

    document.body.classList.toggle('nattramn-loading', loading);
  }
});

if (document.querySelector('nattramn-router').innerHTML !== null) {
  document.body.classList.remove('nattramn-loading');
}

window.__onGCastApiAvailable = isAvailable => {
  if (isAvailable) {
    cast.framework.CastContext.getInstance().setOptions({
      receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.PAGE_SCOPED
    });
  } else {
    window.noCast = true;
  }
};
